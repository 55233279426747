<template>
<div id="contentwrap">
    <PageTitle />
    <div class="row listing-title">
        <div class="col span3of3">
            <h2 class="title">Listings</h2>
        </div>
    </div>
    <div v-if="!$store.getters.getLoaded">
        <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
    </div>
    <Properties v-else v-bind:properties="listingProperties" v-bind:fromHomepage="false" v-bind:ribbonText="true" />
</div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import Spinner from 'vue-simple-spinner'
import Properties from '../components/Properties.vue'


export default {
    name: "Listings",
    components: {
        PageTitle,
        Spinner,
        Properties
    },
    data(){
        return{
            
        }
    },
    metaInfo: {
        title: 'Listings',
        titleTemplate: '%s | Meredith Schlosser',
        meta: [{
            name: 'description', 
            content: "Active and Pending (In Escrow) Listings for Los Angeles Realtor Meredith Schlosser."
        },
        {
            name: 'author', 
            content: "Meredith Schlosser"
        },]
    },
    computed:{
        listingProperties(){
            let properties = this.$store.getters.properties.filter((property) => {
                return property.Status !== 'sold';
            });
            properties = properties.sort((first, second) => {
                if (first.Status === 'comingSoon' && second.Status !== 'comingSoon'){
                    return -1;
                } else if (first.Status !== 'comingSoon' && second.Status === 'comingSoon'){
                    return 1;
                } else if (first.Status === 'newListing' && second.Status !== 'newListing'){
                    return -1;
                } else if (first.Status !== 'newListing' && second.Status === 'newListing'){
                    return 1;
                } else if (first.Status === null && second.Status !== null){
                    return -1;
                } else if (first.Status !== null && second.Status === null){
                    return 1;
                } else if (first.Status === 'inEscrow' && second.Status !== 'inEscrow'){
                    return -1;
                } else if (first.Status !== 'inEscrow' && second.Status === 'inEscrow'){
                    return 1;
                } else if (first.Status === 'sold' && second.Status !== 'sold'){
                    return 1;
                } else {
                    return  Number(second.Price) - Number(first.Price);
                }
            })

            return properties;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>