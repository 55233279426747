<template>
<div>
  <div id="home-listings" class="row home-listings" v-if="fromHomepage">
    <div
      v-for="(item, index) in properties"
      class="property"
      v-bind:key="index"
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-duration="1000"
      v-bind:data-aos-delay="getDelayInMS(index)">
      <router-link :to="`/property/${item.Slug}`" :aria-label="`Go to property ${item.Title}`">
          <img :src="hostUrl+item.DisplayImage.url" :alt="item.Title" />
      </router-link>
      <div v-if="ribbonText && item.Status" class="ribbon-wrapper-green">
        <div class="ribbon-green">
          {{item.Status | startCase}}
        </div>
      </div>
      <div class="banner">
        <div v-if="item.Address" class="left">
          {{item.Address}}
        </div>
        <div v-if="item.Price && !item.Lease" class="right">
          {{item.Price | priceFormat}}
        </div>
        <div v-if="item.Price && item.Lease" class="right">
          {{item.Price | priceFormat}} / mo.
        </div>
      </div>
    </div>
  </div>
  <div id="home-listings" class="row home-listings" v-else>
      <transition-group 
        name="fade"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
        tag="div"
        class="listing-div"
      >
    <div
      v-for="(item, index) in properties"
      class="property"
      v-bind:key="index+0">
      <router-link :to="`/property/${item.Slug}`" :aria-label="`Go to property ${item.Title}`">
          <img :src="hostUrl+item.DisplayImage.url" :alt="item.Title" />
      </router-link>
      <div v-if="ribbonText && item.Status" class="ribbon-wrapper-green">
        <div class="ribbon-green">
          {{item.Status | startCase}}
        </div>
      </div>
      <div class="banner">
        <div v-if="item.Address" class="left">
          {{item.Address}}
        </div>
        <div v-if="item.Price && !item.Lease" class="right">
          {{item.Price | priceFormat}}
        </div>
        <div v-if="item.Price && item.Lease" class="right">
          {{item.Price | priceFormat}}/mo.
        </div>
      </div>
    </div>
    </transition-group  >
  </div>
</div>
</template>

<script>
export default {
    name: "Properties",
    props:['properties', 'fromHomepage', 'ribbonText'],
    data(){
      return {
        hostUrl: 'https://strapi.uptowncreative.io'
      }
    },
    methods:{
      getDelayInMS(position){
        if(position % 3 === 1){
          return 400;
        } else if (position % 3 === 2){
          return 800;
        }
        return 0;
      }
    }
}
</script>

<style lang="scss" scoped>
.ribbon-green{
  text-transform: uppercase;
}
#home-listings{
  width: 100%;
}
.listing-div{
  width: 100%;
}
</style>
